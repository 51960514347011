<template>
  <div class="Release" v-loading="loading">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">发布文章</el-menu-item>
      <el-menu-item index="2">发布快讯</el-menu-item>
    </el-menu>
    <el-form ref="form" :model="form">
      <el-form-item label>
        <span>
          <span style="color:red;">*</span>
          {{ artice }}
        </span>
        <el-input
          @blur.native.capture="SensitiveWorded(form.title)"
          v-model="form.title"
          maxlength="30"
          placeholder="30个字以内"
        ></el-input>
      </el-form-item>
      <el-form-item label v-if="show">
        <span>
          <span style="color:red;">*</span>文章简介
        </span>
        <el-input
          @blur.native.capture="SensitiveWordedIntro(form.intro)"
          v-model="form.intro"
          placeholder="30个字以内"
          oninput="if(value.length>20)value = value.slice(0,30)"
        ></el-input>
      </el-form-item>
      <el-form-item label>
        <div v-if="isShow">
          <span>
            <span style="color:red;">*</span>
            {{ articefe }}
          </span>

          <el-radio-group style="width:100%;" v-model="form.theme">
            <el-radio
              style="margin-top:5px;"
              v-for="item in morelist"
              :key="item.id"
              :label="item.id"
            >{{ item.title }}</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label v-if="show">
        <span>&nbsp;</span>

        <div class="kws">
          <div class="kws-down">
            <span class="keyword" v-for="(item, index) in keywordList" :key="index">
              <span>{{ item }}</span>
              <span class="kw-close" @click="removeWord(item)">x</span>
            </span>
            <span class="add-kw">
              <input
                v-model="word"
                placeholder="请输入关键字,回车确定"
                type="text"
                maxlength="15"
                @keyup.enter="addWord(word)"
              />
            </span>
          </div>
        </div>
      </el-form-item>

      <el-form-item label>
        <span>{{ articecn }}</span>
        <editor-bar v-model="form.content" :isClear="isClear" @change="change"></editor-bar>
        <el-upload
          class="upload-demo"
          :action="surl"
          accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .JPG, .JPEG, .PBG, .GIF, .BMP, .PDF, .docx"
          :on-success="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :before-upload="load"
          multiple
          :limit="1"
          :file-list="fileList"
        >
          <i class="el-icon-paperclip"></i>
          <span class="fenmian">上传附件</span>
        </el-upload>

        <!-- 上传封面图片 -->
        <div class="picture" v-show="show">
          <span class="fenmian">(请选择文章的封面图片，否则系统自动默认一张图片)</span>
          <el-upload
            :action="surl"
            accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .JPG, .JPEG, .PBG"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="PICsuccess"
            :limit="1"
            class="Imege"
            :file-list="fileList1"
          >
            <i class="up-img"></i>
          </el-upload>
          <!-- img-src -->
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </div>
      </el-form-item>

      <el-form-item label>
        <span>引用其他外部知识：</span>
        <el-input
          v-model="form.reference"
          @blur.native.capture="Sensitivereference(form.reference)"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">发布</el-button>
        <el-button type="primary" @click="onPre">预览</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import EditorBar from "@/components/wangEnduit/index";
import { DELETE, QUERY, UPDATE, INSERT,QUERYED } from "@/services/dao.js";
import {
  showTimeFormat,
  formatDate,
  integralAddThree,
  SensitiveWords,
  YearMMdd
} from "@/js/common";
import axios from "axios";
// import { YearMMdd } from "@/js/common/index.js";
export default {
  components: { EditorBar },
  data() {
    return {
      loading: false, //是否显示正在加载中
      activeIndex: "1",
      show: true,
      isClear: false,
      detail: "",
      dialogImageUrl: "", //封面图片
      dialogVisible: false,
      morelist: [],
      keywordList: [],
      artice: "文章标题",
      articefe: "文章类别归属(勾选与文章相关的分类)",
      articecn: "文章描述",
      limt: true,
      form: {
        title: "",
        intro: "",
        keyword: "",
        theme: "",
        img: "", //主题图片
        content: "",
        createTime: "",
        authorName: "I研习",

        reference: "",

        file: "",
        accessory: ""
      },
      isShow: true,

      fileList: [],
      fileList1: [],
      word: [],
      centers: ""
    };
  },
  created() {
    this.surl = this.api.LoginURL.concat("/resources/trading/resourcesUpload");

    this.init();
    console.log(this.$route.params);
    if (this.$route.params.form_message.active !== undefined) {
      this.activeIndex = this.$route.params.form_message.active;
      this.form = this.$route.params.form_message;
      this.keywordList = this.$route.params.keywordList;
      this.isShow = this.$route.params.isShow;
      this.fileList = this.$route.params.fileList;
      this.fileList1 = this.$route.params.fileList1;
      this.dialogImageUrl = this.$route.params.dialogImageUrl;
      console.log(this.form);
    } else {
      this.form = this.$route.params.form_message;
      this.keywordList = this.$route.params.keywordList;
      this.isShow = this.$route.params.isShow;
      this.fileList = this.$route.params.fileList;
      this.fileList1 = this.$route.params.fileList1;
      this.dialogImageUrl = this.$route.params.dialogImageUrl;
    }
    if (this.activeIndex === "1") {
      this.show = true;
      this.form.active = "1";
    } else {
      this.show = false;
      this.form.active = "2";
    }
  },
  methods: {
    async Sensitivereference(name) {
      await this.www(name); ///请求接口
      this.form.reference = this.centers; //赋值
    },
    ///过滤文章简介
    async SensitiveWordedIntro(name) {
      await this.www(name); ///请求接口
      this.form.intro = this.centers; //赋值
    },
    async www(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centers = response.data.message.filterContent;
          console.log("赋值成功：" + this.centers);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    ///过滤文章标题
    async SensitiveWorded(name) {
      await this.www(name);
      this.form.title = this.centers;
    },
    //点击文件列表中已上传的文件时的钩子（图片）
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传图片成功后返回的数据（图片）
    PICsuccess(file, fileList) {
      this.dialogImageUrl = file.data.url;
      console.log("上传返回的图片路径：" + this.dialogImageUrl);
      this.fileList1[0] = fileList;
      this.fileList1[0].url = this.api.LoginURL.concat(file.data.url);
    },
    handleSelect(key, keyPath) {
      switch (keyPath[0]) {
        case "1":
          this.show = true;
          this.form.active = "1";
          this.artice = "文章标题";
          this.articefe = "文章类别归属(勾选与文章相关的分类)";
          this.articecn = "文章描述";
          this.isShow = true;
          break;
        case "2":
          this.show = false;
          this.form.active = "2";
          this.artice = "快讯标题";
          this.articefe = "快讯类别归属(勾选与文章相关的分类)";
          this.articecn = "快讯描述";
          this.isShow = false;
          break;
      }
    },
    async init() {
      let res = await QUERY("post", "", "Theme{    id  img  title}");
      this.morelist = res.data.Theme;
    },
    async onSubmit() {
      let isUser = this.$store.state.userinfo.id;
      if (isUser == undefined || isUser == null || isUser == "") {
        //alert("请先登录!");
        this.$message({
          message: "请先登录",
          type: "warning"
        });
        return false;
      } else {
        // 如果是发布文章才判断 3个红星是否为空
        if (this.isShow) {
          if (
            this.form.title == "" ||
            this.form.theme == "" ||
            this.form.intro == ""
          ) {
            // console.log("这几个的值： ",this.form.title,this.form.intro,this.form.theme)
            //alert("红星为必填项!");
            this.$message({
              message: "红星为必填项!",
              type: "warning"
            });
            return false;
          }
        } else {
          // 如果是发布的快讯只判断一个红星条件
          if (this.form.title == "") {
            //alert("红星为必填项!");
            this.$message({
              message: "红星为必填项!",
              type: "warning"
            });
            return false;
          }
        }
      }
      if ((this.limt = false)) {
        this.$message({
          message: "附件上传中",
          type: "warning",
          duration: "1000"
        });
      } else {
        // 发布文章
        if (this.show === true) {
          this.loading = true; //隐藏
          this.form.createTime = new Date();
          ////判断是否上传封面图片
          if (this.dialogImageUrl == null || this.dialogImageUrl == "") {
            let ress = await QUERY(
              "post",
              "",
              "Theme(where:{id: {_eq: " +
                this.form.theme +
                "}}) {    id  img  }"
            );
            this.dialogImageUrl = ress.data.Theme[0].img;
          } else {
            this.dialogImageUrl = this.dialogImageUrl;
          }
          //alert(this.dialogImageUrl)
          let info = await INSERT(
            "POST",
            "",
            'insert_TtArticle(objects: {type: 1,coverImg : "' +
              this.dialogImageUrl +
              '", title: "' +
              this.form.title +
              '",author: "' +
              this.$store.state.userinfo.id +
              '",keyword:' +
              JSON.stringify(JSON.stringify(this.keywordList)) +
              ', theme:"' +
              this.form.theme +
              '", createTime: "' +
              this.form.createTime +
              '", img: "", intro:"' +
              this.form.intro +
              '", reference: "' +
              this.form.reference +
              '",accessory: "' +
              this.form.file +
              '",time: ' +
              parseInt(new Date()/1000) +
              ', content: ' +
              JSON.stringify(JSON.stringify(this.form.content)) +
              "}) {    affected_rows  returning{id}   }"
          );
          console.log("return: ", info);
          if (info.data.insert_TtArticle.affected_rows == "1") {
            this.$message({
              message: "发布成功",
              type: "success",
              duration: "1000"
            });
            this.loading = false; //隐藏
            this.$router.push({ name: "index", params: { userId: 123 } });
          } else {
            this.$message({
              message: "发布失败!",
              type: "warning",
              duration: "1000"
            });
          }

          // eslint-disable-next-line no-empty
        }
        // 发布快讯
        else {
          this.loading = true; //隐藏
          this.form.createTime = new Date();
          let info = await INSERT(
            "POST",
            "",
            'insert_TtArticle(objects: {type: 2, title: "' +
              this.form.title +
              '",author: "' +
              this.$store.state.userinfo.id +
              '",keyword:' +
              JSON.stringify(JSON.stringify(this.keywordList)) +
              ',createTime: "' +
              this.form.createTime +
              '", img: "", intro:"' +
              this.form.intro +
              '", reference: "' +
              this.form.reference +
              '",accessory: "' +
              this.form.file +
              '", content: ' +
              JSON.stringify(JSON.stringify(this.form.content)) +
              "}) {    affected_rows  returning{id}   }"
          );
          console.log(info);
          if (info.data.insert_TtArticle.affected_rows == "1") {
            this.$message({
              message: "发布成功",
              type: "success",
              duration: "1000"
            });
            this.loading = false; //隐藏
            this.$router.push({ name: "index", params: { userId: 123 } });
          }
        }
      }
      let jifen = await QUERYED(
        "post",
        "",
        "TtArticle(where: {author : {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {  id createTime  }"
      );
      let jf_legth = [];
      for (let item of jifen.data.TtArticle) {
        if (YearMMdd(item.createTime) == YearMMdd(new Date())) {
          jf_legth.push(item);
        }
      }
      if (jf_legth.length <= 5) {
        integralAddThree(isUser); ////加三分积分
      }
    },
    onPre() {
      this.form.createTime = new Date();
      console.log(this.form);
      console.log("33333", this.fileList);
      this.$router.push({
        name: "Preview",
        params: {
          form_message: this.form,
          keywordList: this.keywordList,
          isShow: this.isShow,
          fileList: this.fileList,
          fileList1: this.fileList1,
          dialogImageUrl: this.dialogImageUrl
        }
      });
    },
    async change(val) {
      await this.www(val); ///请求接口
      this.form.content = this.centers; //赋值
      // console.log(val);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    load() {
      this.limt = false;
    },
    handlePreview(file, fileList) {
      console.log(fileList);
      this.form.name = file.data.fileName + "." + file.data.type;
      this.form.file = file.data.url;

      this.fileList[0] = fileList;
      this.fileList[0].url = file.data.url;
    },

    handleExceed(files, fileList) {
      var that = this;
      that.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    async addWord(name) {
      var that = this;
      if (this.word == "" || this.word == null) {
        that.$message.warning(`标签内容不能为空`);
      } else {
        if (this.keywordList.length == 3) {
          this.$message({
            message: "最多只能添加3个关键字",
            type: "warning"
          });
          //alert("最多只能添加3个关键字");
          return;
        }
        await this.www(name); ///请求接口
        this.word = this.centers; //赋值
        console.log("赋值成功：" + this.word);
        this.keywordList.push(this.word);
        this.word = "";
        this.$emit("getKeyword", this.keywordList);
      }
    },
    removeWord(word) {
      this.keywordList.forEach((item, index) => {
        if (item == word) {
          this.keywordList.splice(index, 1);
        }
      });
      this.$emit("getKeyword", this.keywordList);
    }
  }
};
</script>

<style scoped>
.Release {
  background-color: #ffffff;
  margin: 17px 310px 0 340px;
}
.el-menu-item {
  margin-left: 138px;
}
.el-form-item {
  margin-top: 43px;
  margin-bottom: 22px;
}
.el-form {
  margin: 25px;
  padding-bottom: 25px;
}
.el-form button {
  float: right;
  margin-right: 20px;
}
.kws {
  border: 1px solid rgba(210, 210, 210, 1);
  padding: 10px;
  height: 90px;
}
.kws-up {
  padding: 10px;
  font-size: 14px;
}
.kws-down {
  position: relative;
  min-height: 38px;
}
.kws-up .sub-modify {
  float: right;
  color: #0084ff;
  cursor: pointer;
}
.kws-down .keyword {
  display: inline-block;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 14px;
  padding: 3px 10px;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 12px;
}
.kws-down .keyword .kw-close {
  vertical-align: top;
  display: inline-block;
  width: 13px;
  height: 8px;
  line-height: 8px;
  margin-left: 5px;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  position: relative;
  right: -5px;
  /* border: 1px solid red; */
  cursor: pointer;
}
.kws-down .keyword .kw-close:hover,
.kws-down .add-kw:hover {
  color: red;
}
.kws-down .add-kw {
  position: absolute;
  right: 0;
  bottom: 4px;
  height: 28px;
  line-height: 28px;
  margin-left: 10px;
  padding: 3px 10px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 12px;
}
.kws-down .add-kw input {
  height: 26px;
}
.up-img:hover {
  background-color: rgba(210, 210, 210, 1);
}
.up-img {
  margin-left: 10px;
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-img.png);
  background-repeat: no-repeat;
  background-position-x: 14px;
  background-position-y: 10px;
  position: relative;
  left: -20px;
  top: -6px;
  background-color: white;
  cursor: pointer;
  /* border: 1px solid red; */
}
.fenmian {
  /* border: 1px solid red; */
  display: inline-block;
  color: grey;
  font-size: 10px;
}
</style>
